<template>

  <section id="gas">

    <div class="row my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Revisa las solicitudes por colaborador y actualiza su estado.
          Recuerda que podrás seleccionar un periodo de interés y descargar un reporte.
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-6 col-lg-6 input-search">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar colaborador" 
                v-model.trim="filter.search" 
                @keyup.enter="getPaginas()"
                ref="searchInput"
                />
                <span class="input-search-icon" @click="getPaginas()">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col-6 col-lg-6">
            <v-select
              class="selvue-custom"
              :clearable="false"
              :options="data_estados" 
              v-model="sel_estados"
              placeholder="Categoría">
              <span slot="no-options"></span>
            </v-select>  
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            Filtrar por periodo:
          </div>
          <div class="col-10 col-lg-7 col-xl-7 col-xxl-7">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
          </div>
          <div class="col-2 col-lg-1 d-flex">
            <export-json-excel
              class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill"
              :data="data_excel"
              :fields="fields_excel"
              :name="name_excel">
              <font-awesome-icon icon="download"/>
            </export-json-excel>
          </div>
        </div>
      </div>
    </div>

      <div class="row">
        <div class="col-12 col-lg-7">
            <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 d-flex align-items-center justify-content-between">
                        <h5 class="font-main-bold color-gray">
                          Solicitudes 
                        </h5>
                        <div class="form-check form-switch form-check-reverse">
                          <input 
                            v-model="all_check"
                            class="form-check-input link-cursor" 
                            type="checkbox"
                            id="seleccionarTodos" 
                            role="switch">
                            <label 
                              class="form-check-label link-cursor text-secondary" 
                              for="seleccionarTodos">
                              Seleccionar todos
                            </label>
                        </div>
                      </div>
                    </div>

                    <Loading 
                      v-if="loading"
                      pixeles="150px"
                    />
                    <template v-if="!loading && final_list.length !== 0">
                      <ul class="list-group list-group-flush scroll-custom-550 mt-3" :key="list_key">
                        <li class="list-group-item">
                          <div class="row">
                            <div class="col-lg-5 color-gray ps-3"><strong>Usuario</strong></div>
                            <div class="col-lg-4 text-center color-gray"><strong>Fecha</strong></div>
                            <div class="col-lg-3 text-center color-gray"><strong>Estado</strong></div>
                          </div>
                        </li>
                        <li
                          v-for="(ad, i) in final_list"
                          :key="ad.id_solicitud"
                          href="javascript:"
                          class="list-group-item"
                          :class="selectedUserList(ad.id_solicitud)"
                          @click="selectSoli(ad, i)"
                        >
                          <div class="row">
                            <div class="col-lg-5 d-flex align-items-center">
                              <div class="flex-shrink-0">
                                <input 
                                  :ref="`typeBox`"
                                  v-model="checks"
                                  :value="{
                                    solicitud: ad.id_solicitud,
                                    user: ad.usuario.id_usuario,
                                  }"
                                  class="form-check-input link-cursor me-3"
                                  type="checkbox"
                                  @click.stop=""
                                > 
                              </div>
                              <img 
                                class="img-obj img-obj-60 img-obj-circle border me-3" 
                                :src="handleUserImage(ad.usuario.imagen_perfil)"
                              />
                              <div>
                                <p class="text-secondary m-auto">
                                  {{ ad.usuario.primer_nombre | capitalize }}
                                  {{ ad.usuario.apellido_paterno | capitalize }}
                                  {{ ad.usuario.apellido_materno | capitalize }}<br>
                                  <small class="text-black-50">
                                    {{ ad.usuario.cargo[0].nombre_cargo }}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <div class="col-lg-4 text-center d-flex align-items-center">
                              <p class="font-size-14 text-secondary m-auto">
                                {{ dateNewFormat(ad.fecha_creacion, 1) }}
                              </p>
                            </div>
                            <div class="col-lg-3 text-center d-flex align-items-center justify-content-center">
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'En Proceso'"
                                class="font-size-14 badge badge-custom badge-custom-yellow-light">
                                En proceso
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Confirmada'"
                                class="font-size-14 badge badge-custom badge-custom-blue-light">
                                Corfirmada
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Espera Retiro'"
                                class="font-size-14 badge badge-custom badge-custom-blue-light">
                                Espera retiro
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Finalizada'"
                                class="font-size-14 badge badge-custom badge-custom-green-light">
                                Finalizada
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Cancelada'"
                                class="font-size-14 badge badge-custom badge-custom-orange-light">
                                <i class="fa-regular fa-circle-xmark"></i>
                                Cancelada
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Rechazada'"
                                class="font-size-14 badge badge-custom badge-custom-red-light">
                                <i class="fa-regular fa-circle-xmark"></i>
                                Rechazada
                              </span>

                            </div>
                          </div>
                        </li>
                      </ul>

                    </template>
                    <SinResultados 
                      msg="Aceptar" 
                      custom-click 
                      @click="nuevaBusqueda" 
                      v-if="!loading && final_list.length === 0"/>

                    <div class="row mt-3">
                      <div class="col-12">
                        <button class="btn btn-custom-color-white border d-block" :disabled="total_parcial>=total_registros" @click="paginador()"> 
                          <i class="fa-solid fa-arrows-rotate color-blue pe-1"></i>
                          Mostrar más ( {{total_parcial+" / "+total_registros}})
                        </button>
                      </div> 
                    </div>
                  </div>
            </div>

        </div>
        <div class="col-12 col-lg-5">
          <div class="card border-round-10 card-shadow border-0 mb-3">
            <div class="card-body">
  
              <Loading 
                v-if="loading"
                pixeles="150px"
              />

              <template v-if="!loading && final_list.length !== 0">             
                <div class="row mb-3">
                  <div class="col-12 d-flex align-items-center justify-content-between">
                    <h5 class="font-main-bold color-gray mb-0">
                      Información del usuario
                    </h5>
                    <span 
                      v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'En Proceso'"
                      class="font-size-14 badge badge-custom badge-custom-yellow-light">
                      En proceso
                    </span>
                    <span 
                      v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Confirmada'"
                      class="font-size-14 badge badge-custom badge-custom-blue-light">
                      Corfirmada
                    </span>
                    <span 
                      v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Espera Retiro'"
                      class="font-size-14 badge badge-custom badge-custom-blue-light">
                      Espera retiro
                    </span>
                    <span 
                      v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Finalizada'"
                      class="font-size-14 badge badge-custom badge-custom-green-light">
                      Finalizada
                    </span>
                    <span 
                      v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Cancelada'"
                      class="font-size-14 badge badge-custom badge-custom-orange-light">
                      <i class="fa-regular fa-circle-xmark"></i>
                      Cancelada
                    </span>
                    <span 
                      v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Rechazada'"
                      class="font-size-14 badge badge-custom badge-custom-red-light">
                      <i class="fa-regular fa-circle-xmark"></i>
                      Rechazada
                    </span>
                  </div>
                </div>
    
                <div 
                  v-if="checks.length >= 2"
                  class="alert alert-info text-center">
                  ¿Cambiar el estado de las <strong>{{ checks.length }} solicitudes?</strong>
                </div>
    
                <div class="row mb-3">
                  <div class="col-12">
                    <v-select
                      :key="sel_selected_estados.id"
                      class="selvue-custom"
                      :clearable="false"
                      :options="data_selected_estados" 
                      v-model="sel_selected_estados"
                      placeholder="Categoría">
                      <span slot="no-options"></span>
                    </v-select>  
                  </div>
                </div>
    
                <ul 
                  v-show="checks.length < 2"
                  class="list-group list-group-flush font-size-14 scroll-custom-400">
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-6">
                          Nombre
                      </div>
                      <div class="col-6 text-end">
                        <p 
                          v-if="selected_solicitud !== null"
                          class="text-secondary mb-0">
                          {{ selected_solicitud.usuario.primer_nombre | capitalize }}
                          {{ selected_solicitud.usuario.apellido_paterno | capitalize }}
                          {{ selected_solicitud.usuario.apellido_materno | capitalize }}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-6">
                        Rut
                      </div>
                      <div class="col-6 text-end">
                        <p class="text-secondary mb-0">
                          {{ selected_solicitud == null ? ""
                              : `${selected_solicitud.usuario.rut_usuario}-${selected_solicitud.usuario.dv_usuario}`
                          }}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row">
                      <div class="col-6">
                          Fecha de ingreso
                      </div>
                      <div class="col-6 text-end">
                        <p class="text-secondary mb-0">
                          {{ selected_solicitud == null ? ""
                            : `${selected_solicitud.usuario.fecha_ingreso}`
                          }}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li v-for="(form, i) in isSelected()" :key="i" class="list-group-item">
                    <div v-if="form.formulario_selector.tipo_dato !== 'Archivo'" class="row">
                      <div class="col-5">
                        {{ form.formulario_selector.nombre_header }}
                      </div>
                      <div class="col-7 text-end">
                        <p class="text-secondary mb-0" 
                          v-html="dataTypeValor(form.formulario_selector.tipo_dato, form.valor)"
                          ></p>
                      </div>
                    </div>
                    <div v-if="form.formulario_selector.tipo_dato === 'Archivo'" class="row">
                      <div class="col-12">
                        <i class="fa-solid fa-circle-arrow-down color-main"></i>
                        {{ form.formulario_selector.nombre_header }}
                      </div>
                      <div class="col-12 text-end">
                        <figure 
                          class="mb-0" 
                          v-html="dataTypeValor(form.formulario_selector.tipo_dato, form.valor)">
                        </figure>
                      </div>
                    </div>
                  </li> 
                </ul>
    
                <div class="row mt-3">
                  <div class="col-12 text-end">
                    <button
                      @click="changeStatus()"
                      v-if="selected_solicitud != null"
                      class="btn btn-custom-color-blue border"
                      :class="this.checks.length > 0 && sel_selected_estados.label != estado_selected.nombre ? '' : 'disabled' "
                    >
                      Guardar cambios
                    </button>
                  </div>
                </div>
              </template>

              <template v-if="!loading && final_list.length === 0">
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-info text-center mb-0">
                      Sin resultados para mostrar
                    </div>
                  </div>
                </div>
              </template>

            </div>
          </div>
        </div>
      </div>

    <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="true"
        @close="closeStatus"
      />
  </section>

</template>

<script>
  import Status from "../Modales/Status.vue";
  import { mapActions, mapState } from "vuex";
  import "export-json-excel";
  import moment from "moment";

  //FF
  import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
      Status,

      //FF
      Loading,
      InputDate,
      SinResultados 
    },
    data() {
      return {
        data_excel:[],
        fields_excel: [],
        name_excel: '',
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        user_id: this.$ls.get("user").id_usuario,
        pagina_actual: 1,
        ids_solicitudes: [""],
        estado_selected: "En espera",
        user_default_img: require("@/assets/img/user_default_img.svg"),
        open_modal_status: false,
        modal_status_msg: false,
        all_check: false,
        checks: [],
        selected_solicitud: { estado_solicitud: { nombre: "" } },
        list_key: 0,
        id_param: this.$route.params.id,
        myli: { data: [], num: 0 },
        total_registros: 0,
        total_parcial: 0,

        //FF
        loading: true,
        filter: { 
          search: ''
        },
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        },
        sel_estados: {id: 1, label: "En proceso", type: "En proceso" },
        sel_selected_estados: {},
        data_selected_estados: []
      };
    },
    created: function () {
      this.getEstados();
    },
    computed: {
      ...mapState("benefitModule", [
        "benefit_list_edits",
        "data_estados",
        "final_list",
        "paginas_solicitud",
        "termimno_carga"
      ]),
      attributes() {
        return {
          highlight: true,
          color: "#009CDE",
          dates: new Date(),
        };
      },
    },
    watch: {
      all_check(check) {
        if (check) {
          this.checkAllInputs();
        } else {
          this.checks = [];
          this.checks.push({
            solicitud: this.final_list[0].id_solicitud,
            user: this.final_list[0].usuario.id_usuario,
          });
          this.selectSoli(this.final_list[0], 0);
        }
      },
      sel_estados() {
        this.pagina_actual = 1;
        this.getPaginas();
      },
      $route: {
        handler: function () {
          this.sel_estados = {id: 1, label: "En proceso", type: "En proceso" };
          this.pagina_actual = 1;
          this.id_param = this.$route.params.id;
          this.all_check = true;
          this.all_check = false;
          this.selected_solicitud = null;
        },
        immediate: true,
      },
      // Esto se agrega para poder controlar el tiempo de renderizado con la lista de beneficios
      termimno_carga() {
        this.loading = false;
        this.total_parcial = this.final_list.length;
        this.getDataExcel();
      },
      //FF
      checks() {
        this.sel_selected_estados.id = this.estado_selected.id_estado_solicitud;
        this.sel_selected_estados.label = this.estado_selected.nombre;
        this.sel_selected_estados.type = this.estado_selected.__typename;
      }, 
      "filter.search"(value) {
        if(value === '') {
          this.getPaginas();
        }
      },
      "range.date_start"() {
          this.getPaginas();
      },
      "range.date_end"() {
          this.getPaginas();    
      },
    },
    mounted() {
      if (this.$route.params.id != undefined && this.$route.params.id != null) {
        //console.log("ingreso por router params");
          //this.getAdminListBenefit();
      }
      else{
        console.log("no ingreso por router params");
      }    
    },
    methods: {
      ...mapActions("benefitModule", [
        "getAdminListSoliAction",
        "changIdSolicitudAction",
        "getEstadosSolicitud",
        "cambioEstadoSolicitud",
        "getPaginacionSolicitudes",
        "getListSolicitudes",
        "getListSolicitudesAppend"
      ]),
      
      nuevaBusqueda(){
        this.filter.search = '';
        this.$refs.searchInput.focus();
        this.getPaginas();
      },
      selectedUserList(id) {
        var results = this.checks.filter((check) => {
          return check.solicitud == id;
        });
        return results.length > 0 && "bg-black-light";
      },
      selectSoli(sol, i) {
        this.selectEstado(sol.estado_solicitud);
        this.$nextTick(() => {
          this.$refs.typeBox[i].focus();
        });
        this.selected_solicitud = sol;
        this.checks = [];
        this.checks.push({
          solicitud: sol.id_solicitud,
          user: sol.usuario.id_usuario,
        });
        //console.log(i)
      },
      // paginador: cambia la variable de las paginas para luego volver a buscar los datos de la siguiente pagina
      paginador(){
        this.pagina_actual = this.pagina_actual +1;
        if (this.pagina_actual > this.paginas_solicitud.length){
          this.pagina_actual = this.paginas_solicitud.length;
          //this.getPaginas();
        }
        else if(this.pagina_actual < 1){
          this.pagina_actual = 1;
          //this.getPaginas();
        }
        else if(this.pagina_actual <= this.paginas_solicitud.length){
          this.getPaginas();
        }
        //console.log("revisando la pagina: ", this.pagina_actual);
        
      },
      async getEstados() {
        this.loading = true;
        let data = {}
        await this.getEstadosSolicitud(data).then(resp=>{
          if(resp!=null){
            resp.forEach((element) =>  this.data_selected_estados.push({
                id: element.id_estado_solicitud,
                type: element.__typename,
                label: element.nombre
              }));
            }
            else{
              console.log("getEstados");
              // agregar el control de las fallas.
            }
            this.loading = false;
        }).catch((err) => {
            console.log("err getEstadosSolicitud", err);
            this.loading = false;
          });
      },
      // obtiene las paginas y luego llama al getListSolictudes, pasarle los Id asociados a la pagina actual
      async getPaginas(){
        this.loading = true;
        let data_ = {
          id_empresa: this.id_empresa,
          id_tipo_solicitud: this.$route.params.id,
          historico: this.range.date_start === null || this.range.date_end === null ? "0" : "1",
          fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
          fecha_final: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
          nombre_usuario: this.filter.search,
          id_estado: this.sel_estados.id == '' ? '0': this.sel_estados.id.toString(),
          limite: "40"
        }
        
        await this.getPaginacionSolicitudes(data_).then(res=>{
            if (res)
            {
                res.map((data) => {
                if (data.pagina == this.pagina_actual) 
                {
                  this.ids_solicitudes = data.solicitudes;
                  let variables = {
                    id_empresa: this.id_empresa,
                    id_tipo_solicitud: this.$route.params.id,
                    id_solicitud: this.ids_solicitudes
                  }
                  if(this.pagina_actual>1){
                    this.getListSolicitudesAppend(variables).then(resp=>{
                      if(resp!=null && resp){
                          if(resp.length < 1) this.selected_solicitud = null;
                          this.list_key = 1 + this.list_key;
                        }
                        else{
                          //console.log("Sin datos para mostrar getListSolicitudesAppend");
                          this.loading = false;
                          // agregar el control de las fallas.
                        }
                        
                    }).catch((err) => {
                      console.log("sin datos getListSolicitudesAppend", err);
                      this.loading = false;
                    });
                    
                  }
                  else if(this.pagina_actual == 1)
                  {
                    this.getListSolicitudes(variables).then(resp=>{
                      if(resp!=null && resp){
                          if(resp.length < 1) this.selected_solicitud = null;
                          if(resp.length>0) this.selectSoli(resp[0], 0);
                          //this.list_view(resp);
                          this.list_key = 1 + this.list_key;
                        }
                        else{
                          console.log("Sin datos para mostrar getListSolicitudes");
                          // agregar el control de las fallas.
                        }
                    }).catch((err) => {
                      console.log("sin datos getListSolicitudes", err);
                      this.loading = false;
                    });
                  }
                }
              });
            }
        }).catch(err=>{
          this.loading = false;
          console.log("Falla getPaginacionSolicitudes: ", err);
        });
        this.total_registros = this.paginas_solicitud[0].total;
        
      },
      isSelected() {
        if (this.selected_solicitud == null) {
          return [];
        } else {
          return this.selected_solicitud.detalle_solicitud[0].valores_formulario;
        }
      },
      checkAllInputs() {
        this.checks = [];
        // Se ocupa la misma variable final_list para aplicar los check de selección multiple
        for (let i = 0; i < this.final_list.length; i++) {
          this.checks.push({
            solicitud: this.final_list[i].id_solicitud,
            user: this.final_list[i].usuario.id_usuario,
          });
        }
      },
      selectEstado(state) {
        this.estado_selected = state;
      },
      handleUserImage(img) {
        if (img !== null && img !== "" && img !== undefined) {
          return img;
        } else {
          return this.user_default_img;
        }
      },
      closeStatus() {
        this.open_modal_status = false;
      },
      saveChanges() {
        this.open_modal_status = true;
        this.modal_status_msg = "Cambios guardados con éxito";
      },
      //Formatear fecha
      dateNewFormat(date, type) {
        var day = new Date(date);
        var month = day.getMonth();

        if (type == 1) {
          return `${day.getDate()}/${
            month + 1 < 10 ? `0${month + 1}` : month + 1
          }/${day.getFullYear()}`;
        }
        if (type == 2) {
          return `${day.getDate()};${this.meses[month]}`;
        }
      },
      // cambiador de estados de solicitudes
      async changeStatus() {
        if (
          this.checks.length > 0 &&
          this.sel_selected_estados.label != this.estado_selected.nombre) {
          console.log("ENTRAR");
          this.loading = true;
          for (let i = 0; i < this.checks.length; i++) {
            let data = {
              id_empresa: this.id_empresa,
              id_estado: this.sel_selected_estados.id,
              id_solicitud: this.checks[i].solicitud,
              id_usuario: this.checks[i].user,
              id_administrador: this.user_id,
            };
            //console.log("contenidos", data);
            await this.cambioEstadoSolicitud(data).then(resp=>{
              console.log("Data cambio de estado solicitud", resp);
            });
          }
          this.loading = false;
          this.checks = [];
          this.all_check = false;
        }
      },
      list_view(li) {
        var li_ = li;
        var li_adds = this.benefit_list_edits[this.id_param];
        if (li_adds != undefined) {
          if (li_adds.length != this.myli.num) {
            for (let i = 0; i < li_adds.length; i++) {
              var ind = li.findIndex(
                (my_li) => my_li.id_solicitud == li_adds[i].id_solicitud
              );
              li_.splice(ind, 1, li_adds[i]);
            }
            this.myli.data = li_;
            this.myli.num = li_adds.length;
          }
        }
        this.final_list = li_;
        this.checks = [];
      },
      dataTypeValor(type, valor) {
        if (type == "Ticket" && valor == "true") {
          return `<span class="badge badge-custom badge-custom-green-light font-size-14"><i class="fa-solid fa-check"></i></span>`;
        }
        if (type == "Ticket" && valor == "false") {
          return `<span class="badge badge-custom badge-custom-gray-light font-size-14"><i class="fa-solid fa-ban"></i></span>`;
        }
        if (type == "Archivo") {
          return `<img class="w-100 img-fluid img-thumbnail rounded" src="${valor}"/>`;
        } else {
          return valor;
        }
      },
      dateFormat(date){
        let new_date = new Date(date);
        let month = new_date.getMonth() + 1 < 10
              ? `0${new_date.getMonth() + 1}`
              : new_date.getMonth() + 1;
        let day = new_date.getDate() < 10 ? `0${new_date.getDate()}` : new_date.getDate();
        let year = new_date.getFullYear();
        return day+'-'+month+'-'+year;
      },
      // Exportar los datos a Excel de cada tipo de solicitud selecionada por el usuario
      async getDataExcel() {
        let data_content = [];
        let doc_name = null;
        let name_actual_date = '_'+this.dateFormat(new Date());
        // Se preparan los datos que no cambian en ninguna solicitud. 
        let fields = [
            { title: "RUT", name: "rut" },
            { title: "Nombres", name: "nombre" },
            { title: "Apellidos", name: "apellido" },
            { title: "Estado solicitud", name: "estado_solicitud" },
            { title: "Fecha solicitud", name: "fecha_solicitud" },
          ];
        let params_solcitudes = [];
        let is_params = true;
        this.final_list.forEach(final => {
          let export_info_obj = {};
          let valor = {};
          try
          {
              final.detalle_solicitud[0].valores_formulario.forEach(val => {
                let name_valor = 
                  val.valor == "true" || val.valor == "false"
                    ? val.valor == "true"
                      ? "Si"
                      : "No"
                    : val.valor;
                let name_param = val.formulario_selector.nombre_header.trim().replaceAll(' ','_').replaceAll('-','_').replaceAll('.','').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                let valor_new = `{ "${name_param}" : "${name_valor}" }`;
                let valor_new_parce = JSON.parse(valor_new);
                valor = Object.assign(valor, valor_new_parce);
                if(is_params){
                  let new_params = { title: val.formulario_selector.nombre_header, name: name_param }
                  fields.push(new_params);
                  params_solcitudes.push(new_params);
                }
            });
          }
          catch(error){
            console.log("data con falla al exportara excel: ", final);
            console.log("data error: ", error);
          }
          // Variable que controla, que se creen los indices de cada solicitud (datos a descargar), solo una vez
          is_params = false;
          export_info_obj = {
              apellido: final.usuario.apellido_paterno+ ' '+final.usuario.apellido_materno,
              nombre: final.usuario.primer_nombre+' '+final.usuario.segundo_nombre,
              rut: final.usuario.rut_usuario+ '-'+final.usuario.dv_usuario,
              estado_solicitud: final.estado_solicitud.nombre,
              fecha_solicitud: final.fecha_creacion
            };
          doc_name = doc_name==null?'Reporte_'+ final.tipo_solicitud.nombre_solicitud + name_actual_date : doc_name;

          if(Object.keys(valor).length != 0 && valor.constructor === Object){
          
            params_solcitudes.forEach(param =>{
              export_info_obj[param.name] = valor[param.name];
            });
            data_content.push(export_info_obj);
          }
        });
        console.log("data_content: ", data_content);

        this.fields_excel = fields;
        this.data_excel = data_content;
        this.name_excel = doc_name;
      },

      //FF
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      },
    },
      //FF
      filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString().toLowerCase();
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    }
  };
</script>
